.filter-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  max-width: 100%;
  overflow: auto;

  @media screen and (max-width: 800px) { 
    flex-direction: column;
    align-items: stretch;
  }

  h5 {
    color: #666;
    margin: 0 20px 0 0;
    padding-right: 20px;
    border-right: 1px solid #ccc;

    @media screen and (max-width: 800px) { 
      border-right: none;
      padding: 0;
      margin: 0 0 20px;
      text-align: center;
    }
  }

  .form-row {
    margin-bottom: 0;

    @media screen and (max-width: 800px) { 
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }

    input[type=text], input[type=number] {
      min-width: 220px;

      @media screen and (max-width: 800px) { 
        min-width: 0;
        width: 100%;
      }
    }
  }
}