.form-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  position: relative;

  > label {
    font-size: 12px;
    color: #666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;

    .premium-star {
      margin-right: 5px;
      margin-top: -3px;
    }
  }

  > *:not(label) {
    flex: 1;
  }

  .array-form {
    .add-array-item {
      border-radius: 50em;
    }
    
    .array-form-item {
      margin-bottom: 10px;
      position: relative;

      .clear-input {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .clear-input {
    cursor: pointer;
    position: absolute;
    z-index: 2;
    right: 8px;
    opacity: 0.6;

    @media screen and (max-width: 800px) {
      bottom: 18px;
    }

    &:hover {
      opacity: 0.85;
    }
  }

  input[type=text], select, textarea, input[type=url], input[type=number], input[type=date], input[type=email], input[type=password], input[type=date] {
    width: 100%;
    padding: 9px;
    border: 1px solid rgba(0,0,0,0.1);
    color: #7f848e;
    background-color: #fff;
    font-size: 14px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 2px;
    transition: all 0.2s ease;
    text-overflow: ellipsis;

    &:disabled {
      background-color: rgba(0,0,0,0.1);
    }

    &.small {
      max-width: 70px;
      text-align: center;
    }

    &::placeholder {
      color: #c5c9d2;
    }

    &::selection {
      background-color: #AAB0BE;
      color: #fff;
    }
    
    &:focus {
      color: #1d1e21;
      box-shadow: 0 0 3px rgba(0,0,0,0.35);
      outline: none;
    }
  }

  textarea {
    height: 120px;
    min-height: 120px;
  }

  input[type=text] {
    padding-right: 25px;
  }

  input[type=date] {
    font-family: 'Open Sans', sans-serif;
  }

  input[type=checkbox] {
    flex: 0 1;
  }

  .note {
    margin: 0;
    font-size: 14px;
    color: #777;
    background: #f5f5f5;
    border: 1px dashed #ccc;
    padding: 10px;
    border-radius: 3px;
    line-height: 1.5em;
  }
  
  &.column {
    flex-direction: column;
    align-items: flex-start;

    label {
      flex-basis: 0;
      margin: 0 5px 10px;
    }
  }
}
