.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 990;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0,0,0,0.85);
  }

  .popup-content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    max-width: 94%;
    width: 50%;
    overflow: auto;
    background: #fff;
    box-shadow: 0 1px 5px rgba(0,0,0,0.7);
    border-radius: 4px;

    h2 {
      font-size: 28px;
      font-weight: 200;
      padding: 10px;
      text-align: center;
      background: #efefef;
      color: #333;
      margin: 0;
    }

    .buttons-wrapper {
      padding: 20px;

      > * {
        margin: 0 5px;
      }
    }

    .close-popup {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      font-size: 18px;
      opacity: 0.5;
      background: none;
      border: none;
      transition: opacity 0.2s ease;
      z-index: 10;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}