.app-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: auto;
  min-height: 100vh;
}

.app-page-header {
  position: relative;
  padding: 30px;
  background: rgba(255,255,255,0.4);
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) { 
    padding: 15px;
    flex-direction: column;
    text-align: center;
  }

  .add-item {
    font-size: 18px;
    padding: 12px 30px;

    @media screen and (max-width: 800px) { 
      margin-top: 15px;
      font-size: 14px;
      padding: 7px 15px;
    }
  }

  hgroup {
    margin: 0;
    padding: 0;
    flex: 1;

    h2 {
      font-size: 24px;
      margin: 0;
      line-height: 1.5em;
    }

    h4 {
      margin: 15px 0 0;
      font-weight: 300;
      font-size: 14px;
      line-height: 1.5em;

      @media screen and (max-width: 800px) { 
        margin-top: 5px;
      }
    }
  }
}

.app-page-content {
  position: relative;
  padding: 30px;

  @media screen and (max-width: 800px) { 
    .app-loader, .app-error {
      position: relative;
    }

    .app-error {
      transform: none;
      left: 0;
      top: 0;
      margin: 40px 0;
      text-align: center;
    }
  }
}

.app-error, .app-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 300;
}
.ml-10{
  margin-left:10px;
}
.login-box{
  margin-top: 10em;
  .button{
    background: #e0212;
  }
}