$firstColor: #E5957C;
$secondColor: #57578F;

$boxSize: 48px;
$smallBoxSize: 30px;

/*Speed*/
$speed2x: 2s;
$speed3x: 1s;
$speed4x: .5s;

@mixin bounding-box($width, $height){
  width: $width;
  height: $height;
  display: inline-block;
  box-sizing: border-box;
}

@mixin border($borderWidth, $top, $right, $bottom, $left){
  border-top: solid $borderWidth $top;
  border-right: solid $borderWidth $right;
  border-bottom: solid $borderWidth $bottom;
  border-left: solid $borderWidth $left;
}

.cp-spinner {
  @include bounding-box($boxSize, $boxSize);
  position: relative;
  margin: 20px auto;
  //position: absolute;
  //left: 50%;
  //top: 50%;
  //margin-left: - $boxSize / 2;
  //margin-top: - $boxSize / 2;

  &.small {
    @include bounding-box($smallBoxSize, $smallBoxSize);
    //margin-left: - $smallBoxSize / 2;
    //margin-top: - $smallBoxSize / 2;

    &.cp-balls {
      &:before, &:after {
        @include bounding-box($smallBoxSize/2, $smallBoxSize/2);
      }
    }
  }
}

.cp-balls {
  animation: cp-balls-animate $speed3x linear infinite;

  &:before{
    border-radius: 50%;
    content: " ";
    @include bounding-box($boxSize/2, $boxSize/2);
    background-color: $firstColor;
    position: absolute;
    top: 0;
    left: 0;
    animation: cp-balls-animate-before $speed3x ease-in-out infinite;
  }

  &:after{
    border-radius: 50%;
    content: " ";
    @include bounding-box($boxSize/2, $boxSize/2);
    background-color: $secondColor;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: cp-balls-animate-after $speed3x ease-in-out infinite;
  }
}

@keyframes cp-balls-animate{
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

@keyframes cp-balls-animate-before{
  0%{
    transform: translate(-5px, -5px);
  }
  50%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(-5px, -5px);
  }
}

@keyframes cp-balls-animate-after{
  0%{
    transform: translate(5px, 5px);
  }
  50%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(5px, 5px);
  }
}
